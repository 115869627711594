.dashboard {
  th {
    padding: 0px 0px !important;
  }

  td {
    padding: 8px 6px !important;
  }

  td.cell-kpi:hover {
    background-color: hsv(0, 0, 98%) !important;
  }

  th.cell-right-end,
  td.cell-right-end {
    border-right: solid 1px #a0aec0 !important;
  }

  th:last-child,
  td:last-child {
    border-right: none !important;
  }

  thead {
    tr:first-child {
      th {
        border-bottom: 0;
      }
    }

    tr:last-child {
      vertical-align: top;
    }
  }

  // tableに対してpaddingをかけたかったがいい感じにならなかったので、下記のように設定
  tbody {
    tr:last-child {
      height: 94px;

      td {
        padding-bottom: 12px !important;
      }
    }
  }

  // headerとbodyでカラムの位置を揃えるためにantdが強制的にいれている行
  .ant-table-measure-row {
    td {
      padding: 0px !important;
    }
  }

  .ant-table-column-sorters {
    width: 100%;
    justify-content: center; // 中央揃えするため
  }

  .ant-table-column-sorter {
    margin-left: 0px;
    position: absolute;
    bottom: 3%;
    right: 5%;
    opacity: 0.4;
  }

  // .ant-table-column-sort {
  //   .ant-table-column-sorter {
  //     opacity: 1;
  //   }
  // }

  .ant-table-filter-trigger {
    // 何の影響か分からないがズレてるため。
    margin: 0;
    margin-right: 2px;
  }

  .ant-table-filter-trigger .anticon {
    top: inherit;
    bottom: 1%;
    opacity: 0.4;
  }

  .ant-table-filter-trigger.active {
    opacity: 1;
  }
}

.records-table {
  td {
    padding: 0.7em 0.4em !important;
  }

  .ant-table-column-sorters {
    padding: 16px 0px !important;
    padding-left: 4px !important;
  }
}

.dashboard-total {
  td {
    padding-bottom: 6px !important;
  }
}

// 表示が崩れていたので
.ant-steps-small .ant-steps-item-icon {
  line-height: 22px;
}

.property-cascader {
  .ant-cascader-menu {
    width: 150px;
    height: 300px;
  }

  .ant-cascader-menu:first-child {
    width: 450px;
  }

  .ant-cascader-menu:nth-child(3) {
    width: 350px;
  }
}

.user-groups-tree {
  .ant-tree-switcher-line-icon {
    padding-top: 20px !important;
  }

  .ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line::before {
    height: 28px !important;
  }

  .ant-tree-switcher-leaf-line::after {
    height: 28px !important;
  }
}

.ant-tabs-nav {
  margin-bottom: 0 !important;
}

// .ant-tabs-tab, .ant-tabs-nav-add, .ant-tabs-nav-wrap {
//   border-color: #F9FBFD !important;
// }

.business-day-calendar {
  .ant-picker-cell {
    opacity: 0.5;
  }

  .ant-picker-cell-in-view {
    opacity: 1;
  }
}

.my-date-picker {
  .ant-picker-panel {
    border: 1px white solid !important;
    transition: all 0.3s;
  }

  .ant-picker-panel:focus {
    border: 1px #376df8 solid !important;
  }
}

.form-vertical-small-label {
  .ant-form-item-label {
    font-size: 5pt !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}

.description-vertical-small-label {
  .ant-descriptions-item {
    padding-bottom: 0px !important;
  }

  .ant-descriptions-item-label {
    font-size: 9pt;
    color: #888;
  }
}

.ant-tree {
  .ant-tree-treenode {
    .ant-tree-node-hover-navigation {
      display: none;
      opacity: 0;
      transition: all 0.5s; // 機能していない
    }
  }

  .ant-tree-treenode:hover {
    .ant-tree-node-hover-navigation {
      display: inline;
      opacity: 1;
    }
  }
}

.antd-tree-my-directory-tree {
  //
  // 長めのファイル名に対する対応
  //
  .ant-tree-node-content-wrapper {
    display: flex;
    padding: 0 0 0 4px;

    .ant-tree-title {
      position: relative;
      flex: 1;

      .ant-space {
        position: absolute;
        inset: 0;
        display: flex;
        justify-content: space-between;
        gap: 2px;

        .ant-space-item:first-child {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  // .ant-tree-draggable-icon {
  //   text-align: left;

  //   span {
  //     margin-right: 12px;
  //   }
  // }

  //
  // 横のスペースの調整
  //
  // draggable iconとiconの間。
  .ant-tree-switcher {
    width: 6px; // デフォルトは24px。0にすると通常のトグルアイコンとドラッグ
  }

  // .ant-tree-draggable-icon {
  //   width: 12px; // デフォルトは24px
  // }

  .ant-tree-indent-unit {
    width: 20px;
  }
}

.antd-tree-one-level-columns {
  .ant-tree-switcher {
    width: 0px;
  }

  .ant-tree-treenode {
    .anticon-holder svg {
      display: none;
    }
  }

  .ant-tree-treenode:hover {
    .anticon-holder svg {
      display: block;
    }
  }
}

// ダークテーマのSider
.views-sider {
  color: white;

  .title {
    .ant-btn {
      background-color: #001529;
      color: white;
      border-color: #ddd;
      opacity: 0.5;
      transition: all 0.2s;
      // width: 20px;
      // height: 20px;
    }

    .ant-btn:hover {
      // background-color: lighten(#001529, 10%);
      opacity: 1;
    }
  }

  .ant-input-affix-wrapper {
    background-color: lighten(#001529, 10%);
    border-color: lighten(#001529, 10%);

    span {
      color: white;
    }
  }

  .ant-input {
    background-color: lighten(#001529, 10%);
    border-color: lighten(#001529, 10%);
    color: white;
  }

  .ant-input-search-button {
    border-color: lighten(#001529, 10%);
    background-color: lighten(#001529, 10%) !important;

    span {
      color: white;
    }
  }

  .ant-tree {
    background-color: #001529;
    color: #ddd;

    .ant-tree-treenode {
      color: #ddd;

      .ant-btn {
        color: #ddd;
      }
    }

    .ant-tree-treenode:hover {
      color: white;

      .ant-btn {
        color: white;
      }
    }

    .ant-tree-treenode:hover::before {
      background-color: lighten(#001529, 10%);
    }
  }

  .footer {
    .ant-btn {
      color: #ddd;
    }

    .ant-btn:hover {
      color: white;
      background-color: lighten(#001529, 10%);
    }
  }
}

.views-sider-v2 {
  // .ant-tree-title {
  // color: #3d4249; // color指定するとactiveまで影響が出る
  // margin-left: 3px;
  // }

  .ant-space-item {
    font-size: 13px;
    font-weight: 500;
  }

  .ant-tree-switcher-icon {
    color: #999 !important;
  }

  // インデントの調整
  .ant-tree-indent-unit {
    width: 10px;
  }
}

// アニメーションを有効化。主にrotateでの使用
.anticon svg {
  transition: transform 0.3s ease;
}

// v5への移行時に追加
.ant-modal-without-padding {
  .ant-modal-content {
    padding: 0 !important;
  }
}

// Notificationのcloseボタンを現状のプロパティだと削除できないので
.notification-without-close-button {
  .ant-notification-notice-close {
    display: none !important;
  }
}

// 2023/08 実験的に、うえぽんさんスタイルのデザインを適用し始めます
//         全体設計は考慮できておらず、進めるうちに整合性がとれなくなるかも。最悪全て切り戻すくらいの気持ちでやり始めます。
//         .v2-designが適用されるページについては、MemberLayoutのコードを参照して下さい
.v2-desgin {
  .ant-table-thead > tr > td,
  .ant-table-thead > tr > th {
    background-color: #fff;
    color: #777;
    font-weight: normal;
  }

  .ant-btn-primary {
    border-radius: 100px;
    padding: 6px 20px;
    height: 36px;
  }

  .ant-input {
    background-color: #f7f7f7;
    border: none;
    height: 32px;
    padding: 20px 20px;
  }

  .ant-input-search-button {
    display: none;
  }

  .ant-page-header-heading-title {
    font-size: 2.3em;
    margin-bottom: 14px;
  }
}

.sheet-presets-picker-dropdown {
  .ant-dropdown-menu-item {
    padding: 2px 4px !important;
  }
  .ant-dropdown-menu-item:hover {
    background: transparent !important;
  }
  .is-selected {
    color: rgb(59 130 246) !important; // text-blue-500
  }
  .is-selected:hover {
    color: rgb(96 165 250) !important; // text-blue-400
  }
}

.kpi-time-series-form-tabs-wrapper {
  // REF: https://github.com/ant-design/ant-design/issues/9622#issuecomment-724831682
  .ant-tabs {
    height: 100%;
  }
  .ant-tabs-content-holder {
    display: flex;
  }
  .ant-tabs-tabpane {
    height: 100%;
  }
}

.view-ui-kpi-time-series-form-preview-dropdown {
  .ant-dropdown-menu-item {
    cursor: default !important;
  }
  .ant-dropdown-menu-item:hover {
    background-color: transparent !important;
  }
}
