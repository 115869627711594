@duration: 200ms;
@duration-fast: 100ms;

// fadein
.fadein-enter {
  opacity: 0;
}
.fadein-enter-active {
  opacity: 1;
  transition: all @duration ease-out;
}
.fadein-exit {
  opacity: 1;
}
.fadein-exit-active {
  opacity: 0;
  transition: all @duration ease-out;
}

.fadein-appear {
  opacity: 0;
}
.fadein-appear-active {
  opacity: 1;
  transition: all @duration ease-out;
}
.fadein-appear-done {
  opacity: 1;
}

// fadein-quick
.fadein-quick-enter {
  opacity: 0;
}
.fadein-quick-enter-active {
  opacity: 1;
  transition: all @duration-fast ease-out;
}
.fadein-quick-exit {
  opacity: 1;
}
.fadein-quick-exit-active {
  opacity: 0;
  transition: all @duration-fast ease-out;
}

.fadein-quick-appear {
  opacity: 0;
}
.fadein-quick-appear-active {
  opacity: 1;
  transition: all @duration-fast ease-out;
}
.fadein-quick-appear-done {
  opacity: 1;
}

// slide-left-quick
.slide-left-quick-enter {
  opacity: 0;
  transform: translateY(8px);
}
.slide-left-quick-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: all @duration-fast ease-out;
}
.slide-left-quick-exit {
  transform: translateY(0px);
  opacity: 1;
}
.slide-left-quick-exit-active {
  opacity: 0;
  // transform: translateY(8px);
  transition: all @duration-fast ease-out;
}

.slide-left-quick-appear {
  opacity: 0;
}
.slide-left-quick-appear-active {
  opacity: 1;
  transition: all @duration-fast ease-out;
}
.slide-left-quick-appear-done {
  opacity: 1;
}

// page transition
.page-transition-enter {
  opacity: 0;
  transform: translateY(6px);
}
.page-transition-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: all @duration;
}
.page-transition-exit {
  transform: translateY(0px);
  opacity: 1;
}
.page-transition-exit-active {
  opacity: 0;
  transition: all @duration ease-out;
}

.inner-page-transition-enter {
  opacity: 0;
  transform: translateY(6px);
}
.inner-page-transition-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: all @duration-fast ease-out;
}
.inner-page-transition-exit {
  transform: translateY(0px);
  opacity: 1;
}
.inner-page-transition-exit-active {
  opacity: 0;
  transition: all @duration-fast ease-out;
}

// css animation
.blink-slow {
  animation: blink-keyframes 2s infinite alternate;
}

@keyframes blink-keyframes {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.slide-left-property-context-menu-enter {
  opacity: 0.8;
  transform: translateX(100%);
}
.slide-left-property-context-menu-enter-active {
  opacity: 1;
  transform: translateX(0px);
  transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
}

.slide-left-property-context-menu-exit {
  transform: translateX(0px);
  opacity: 1;
}

.slide-left-property-context-menu-exit-active {
  opacity: 0.8;
  transform: translateX(100%);
  transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
}

.slide-left-property-context-menu-appear {
  opacity: 0.8;
  transform: translateX(100%);
}
.slide-left-property-context-menu-appear-active {
  opacity: 1;
  transform: translateX(0px);
  transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-left-property-context-menu-appear-done {
  transform: translateX(0px);
  opacity: 1;
}
