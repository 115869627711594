html {
  touch-action: manipulation;
}

html,
body,
#__next {
  height: 100%;
}

pre {
  white-space: pre-wrap !important; // エラー画面のために使用。他で問題があれば、影響範囲の調査の後改修
  font-family: inherit !important;
}

body {
  background-color: #1b202b;
}

.overflow-scroll-layout-content {
  height: 100%;
  overflow-y: scroll;
}

@media print {
  .dispaly-none-print {
    display: none !important;
  }

  .overflow-visible-print {
    overflow: visible !important;
  }
}

.sider-hover-light {
  transition: background-color 0.2s;
}

.sider-hover-light:hover {
  background-color: #203549;
}

.dashboard {
  .subtotal {
    td {
      border-bottom: 3px double #a0aec0 !important;
    }
  }

  td[rowspan] {
    border-bottom: 3px double #a0aec0 !important;
  }
}

.goals-table > .Spreadsheet > table {
  tr td:first-child {
    padding: 8px 6px;
    border: solid 1px #eee;
    text-align: center;
  }

  tr th {
    font-weight: normal;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }
}

.rsheets-row {
  background-color: #fff;
  transition: background-color 0.1s;

  div {
    transition: background-color 0.1s;
  }
}

// TODO: importantで塗りつぶしてしまうと、validation errorなどの扱いが難しくなる。丁寧にここを設計するのが面倒なので一旦なし
// .rsheets-row:hover {
//   // background-color: #fafafa !important;
//   opacity: 0.9 !important;

//   div {
//     // background-color: #fafafa !important;
//     opacity: 0.9 !important;
//   }
// }

.visible-on-hover {
  opacity: 0;
  transition: opacity 0.1s;
}

.visible-on-hover:hover {
  opacity: 1;
}

.few-visible-on-hover {
  opacity: 0;
  transition: opacity 0.1s;
}

.few-visible-on-hover:hover {
  opacity: 0.1;
}

.grabbing-when-dragging:active {
  cursor: grabbing;
}

.gray-on-hover {
  transition: all 0.2s;
}

.gray-on-hover:hover {
  background-color: red !important;
  // background-color: #E6E8E9 !important;
}

.rsheets {
  .ant-input-number-input {
    padding: 0 !important;
  }
}

.draggable-tab-tabs::-webkit-scrollbar {
  display: none;
}

.cross-tabulation-table-row {
  td {
    vertical-align: top;
  }
}

// スクロールバーの非表示
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.ant-layout-sider {
  .ant-menu-root::-webkit-scrollbar {
    display: none;
  }
}

.html-cell-popover {
  font-size: 10 !important;

  p {
    margin: 0 !important;
    padding: 0 !important;
    line-height: 1 !important;
  }
}

.extracted-calling-metadata-page-chat-message {
  ins {
    background-color: #abf2bc;
  }

  del {
    background-color: #ffc1c0;
  }
}

.extracted-calling-metadata-page-chat-message&.is-user {
  ins {
    background-color: #208020;
  }

  del {
    background-color: #b02021;
  }
}
