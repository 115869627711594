// NOTE: tailwind base を指定するとant designのスタイルが崩れるため、tailwind.config.jsでpreflight: falseにすることでこれを無効化している。
// 指定自体はutilitiesを適切に有効化するために必要
// https://tailwindcss.com/docs/upgrade-guide#base-layer-must-be-present

@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-blue-50 {
  background-color: #eff6ff;
}

.bg-blue-20 {
  background-color: #f8f9ff;
}

.bg-gray-50 {
  background-color: #fdfeff; // background-color-lightと揃える
}

.text-xxs {
  font-size: 5px;
}
